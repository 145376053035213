import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import AccountLinks from "../AccountLinks";
import API from "../../../../api/api";
import { useSelector } from "react-redux";
import { languageLable } from "utilities/helpers";
import { IMAGE_BASE_URL, NoImg, PRODUCT_ACTION } from "utilities/constants";

const api = new API();

export default function ExchangeOrder() {
  const { app, auth } = useSelector((state) => state);
  const history = useHistory();
  const { selectedLanguageData } = app;
  const [libraryOrders, setLibraryOrders] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const locationId =
    auth &&
    auth.defaultAddress &&
    auth.defaultAddress.BusinessCustomerAddressMapping &&
    auth.defaultAddress.BusinessCustomerAddressMapping.LocationId;

  const fetchLibraryOrder = useCallback(async () => {
    try {
      const libraryData = await api.get(
        `GetReplaceOrders?locationId=${locationId}`
      );

      if (libraryData?.Result?.length > 0) {
        setLibraryOrders(libraryData.Result);
        setSearchResults(libraryData.Result);
      }
    } catch (error) {
      console.error("fetchLibraryOrder -> error", error);
    }
  }, [locationId]);

  useEffect(() => {
    fetchLibraryOrder();
  }, [fetchLibraryOrder]);

  const handleOnChange = (e) => {
    const search = e.target.value.toLowerCase();
    const searchMatch = [];
    libraryOrders.forEach((item) => {
      if (
        item.Product?.ProductName.toLowerCase().includes(search) ||
        item.SerialNumber.toLowerCase().includes(search)
      ) {
        searchMatch.push(item);
      }
    });

    setSearchResults(searchMatch);
  };

  const handleExchangeRedirect = (product) => {
    if (product.ProductId) {
      history.push(
        `/product-details/${product?.Product?.GroupId}/Grouped?action=${PRODUCT_ACTION.EXCHANGE}`
      );
    }
  };

  return (
    <section className="ps-my-account ps-page--account">
      <div className="ps-container">
        <div className="row">
          <AccountLinks page="exchange-order" />
          <div className="col-lg-8">
            <div className="ps-page__content">
              <div className="ps-section--account-setting">
                <div class="container-fluid ">
                  <div className="ps-section__header">
                    <h3>
                      {languageLable(selectedLanguageData, "Exchange Order")}
                    </h3>
                  </div>
                  <div className="ps-section__content">
                    <div className="form-group">
                      <Input
                        placeholder="Search"
                        className="form-control"
                        onChange={handleOnChange}
                        allowClear={true}
                      />
                    </div>
                    {searchResults.map((item, index) => {
                      return (
                        <div
                          className="library-product-container"
                          key={`library-order-${index}`}
                        >
                          <div className="library-product">
                            <div className="image-container">
                              {item.Product?.PrimaryMediaId ? (
                                <img
                                  src={`${IMAGE_BASE_URL}${`/Media/GetMediaById?id=`}${
                                    item.Product?.PrimaryMediaId
                                  }`}
                                  alt={item.Product?.ProductName}
                                />
                              ) : (
                                <img
                                  src={NoImg}
                                  alt={item.Product?.ProductName}
                                />
                              )}
                            </div>
                            <div className="product-details">
                              <div className="title">
                                {item.Product?.ProductName}
                              </div>
                              {item.Product?.Attributes.map((attr) => (
                                <div className="description">
                                  {attr.attributeName}{" "}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="action-container">
                            <button
                              className={`ps-btn`}
                              onClick={() => handleExchangeRedirect(item)}
                            >
                              Exchange
                            </button>
                            <div className="sr-number">
                              SR No : {item.SerialNumber}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
